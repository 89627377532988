.unidade {
  /* background-color: aqua; */
  margin-bottom: 1em;
  border-bottom: #eee 1px solid;
}

.apartamento-titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.apartamento-titulo h3 {
  display: flex;
  justify-content: center;
}
/* .apartamento-titulo span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
} */

.unidade-separacao hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

.listaReservas {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  /* align-items: flex-start; */
}

.reservaApartamentoCard {
  width: 225px;
  /* height: 250px; */
  background-color: #f3f3f358;
  border-radius: 5px;
  margin: 0px 10px;
  margin-bottom: 20px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.15);

  cursor: pointer;
}

.reservaDescricao {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 20px 0px;
  margin: 0px 5px 0px 10px;
}
.searchContainer {
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 5px;
  margin-bottom: 20px;
  height: 50px;
}
.tituloPagina {
  font-size: 20px;
  font-weight: 500;
}
.searchBox {
  display: flex;
  flex-direction: row-reverse;
}
@media (max-width: 800px) {
  /* Reservas */
  .listaReservas {
    justify-content: center;
    /* align-items: flex-start; */
  }
  /* Reserva */
  .reservaFotoApartamento {
    width: 40%;
    /* height: 60%; */
  }

  .reservaDescricao {
    width: 60%;
  }

  .reservaApartamentoCard {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .searchContainer {
    justify-content: center;
    flex-direction: column;
  }
  .tituloPagina {
    text-align: center;
  }

  .apartamento-titulo {
    flex-direction: column;
  }
}
@media (max-width: 935px) {
  /* Reservas */
  .listaReservas {
    justify-content: center;
  }

  /* Reserva */
}
